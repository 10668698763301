/** @jsx jsx */
import React, { useEffect, useReducer } from "react"

import Amplify from "@aws-amplify/core";
import { API, Auth,/*Hub, Logger*/ Cache } from "aws-amplify"

import { Box, jsx } from 'theme-ui'
import { graphql, useStaticQuery, Link, navigate } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import styled from '@emotion/styled'
import InfoCard from '../@geocine/gatsby-theme-organization/components/InfoCard'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faDownload, faHome, faEnvelope, faPhone, faPlay, faPause, faSpinner, faStar, faShoppingCart } from "@fortawesome/free-solid-svg-icons";

import ReactPlayer from 'react-player'


// import Layout from "../components/layout"
// import SEO from "../components/seo"

import 'src/css/theme.scss'
// import favicon from '../assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import awsconfig from "../aws-exports";
import { AppsContext } from '../AppsProvider'

// import { Authenticator, SignIn, SignUp, ConfirmSignUp, Greetings } from 'aws-amplify-react';


Amplify.configure(awsconfig);
// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false

const WatermarkLogoDiv = styled('div')`
  position: absolute;
  top: 20px;
  left: 35px;
  opacity: 0.6;
`

const UserOpsDiv = styled('div')`
  
  top: 10px;
  left: 0px;
  
`
// const MySectionHeader = Object.assign({}, AmplifyTheme.sectionHeader, { background: 'orange' });
// const MyTheme = Object.assign({}, AmplifyTheme, { sectionHeader: MySectionHeader });
const MyTheme = {
    signInButtonIcon: { 'display': 'none' },
    googleSignInButton: { 'backgroundColor': 'red', 'borderColor': 'red' },
    button: { 'backgroundColor': 'red', 'borderColor': 'red' },
    navButton: { 'backgroundColor': 'red', 'borderColor': 'red' }


}

// const logger = new Logger('My-Logger');

// const listener = (data) => {

//     switch (data.payload.event) {
    
//         case 'signIn':
//             logger.error('user signed in'); //[ERROR] My-Logger - user signed in
//             break;
//         case 'signUp':
//             logger.error('user signed up');
//             break;
//         case 'signOut':
//             logger.error('user signed out');
//             break;
//         case 'signIn_failure':
//             logger.error('user sign in failed');
//             break;
//         case 'configured':
//             logger.error('the Auth module is configured');
            
//     }
// }


const ProfilePage = () => {
  // console.log('location.state:',location.state)
   
  // const [state, dispatch] = useReducer(reducer, initialState);
  const appsContext = React.useContext(AppsContext)
  
  // const assetObj = (location.state === null) ? appsContext.state.videoPlayer.assetObj : location.state.assetObj
  
  // const vPlayer = { playing: false, isDownloading: false, hrDlSrc: '', assetObj: assetObj }

  const handleClick = (e, arg) => {
    
    if (e){ 
      e.preventDefault();      

      if(arg === 'addToCart'){
          console.log('add to cart...')
          let cart = Cache.getItem('shotwire-shopCart')
          console.log('cart:', cart)

          const expiration = new Date(2020, 12, 18)
        
        if(cart == null){
          console.log('set item...')
          Cache.setItem('shotwire-shopCart', [ appsContext.state.videoPlayer.assetObj.id ], { expires: expiration.getTime() });
          appsContext.dispatch({type:"SHOPCART", shopCart:[ appsContext.state.videoPlayer.assetObj.id ]})
          // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.assetId).style.color = "red";
        }else{
          
          if(!cart.includes(appsContext.state.videoPlayer.assetObj.id)){
            cart.push(appsContext.state.videoPlayer.assetObj.id)
            // e.target.style.color = 'red'
            // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.id).style.color = "red";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "lightblue";
            Cache.setItem('shotwire-shopCart', cart, { expires: expiration.getTime() })
            appsContext.dispatch({type:"SHOPCART", shopCart:cart})           
            console.log('added to shopCart.')
            console.log('cart.', cart)
          }else{
            // e.target.style.color = 'white'
            // document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.id).style.color = "white";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "pink";
            const tmp = cart.filter(item => item !== appsContext.state.videoPlayer.assetObj.id)
            // console.log('tmp:', tmp)
            Cache.setItem('shotwire-shopCart', tmp, { expires: expiration.getTime() })
            cart = Cache.getItem('shotwire-shopCart')
            appsContext.dispatch({type:"SHOPCART", shopCart:cart})
            console.log('removed from shopCart.')
            console.log('cart.:', cart)
         }
       }
          // navigate(
          //     "/",
          //     // {
          //     //   state: { searchform },
          //     // }
          //   )
      }else if(arg === 'buySubscription'){
        // event.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          // console.log('clicked menu link:', event.target)
          // currentCategory = category
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
      }else if(arg === 'listCart'){
        e.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          console.log('list cart:', appsContext.state.shopCart)
          
          // currentCategory = category
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
      }else if(arg === 'listDownloads'){
        e.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          console.log('list myDownloads:', appsContext.state.myDownloads)
          
          // currentCategory = category
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
      }else if(arg === 'listFavorites'){
        e.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          console.log('list favorites:', appsContext.state.favorites)
          
          // currentCategory = category
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
      }else if(arg === 'addFavorite'){
        let favs = Cache.getItem('favorites')

        // console.log('favs:', favs)
        const expiration = new Date(2020, 12, 18)
        
        if(favs == null){
          // console.log('set item...')
          Cache.setItem('favorites', [ appsContext.state.videoPlayer.assetObj.id ], { expires: expiration.getTime() });
          appsContext.dispatch({type:"FAVORITES", favorites:[ appsContext.state.videoPlayer.assetObj.id ]})
          document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.assetId).style.color = "red";
        }else{
          
          if(!favs.includes(appsContext.state.videoPlayer.assetObj.id)){
            favs.push(appsContext.state.videoPlayer.assetObj.id)
            // e.target.style.color = 'red'
            document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.id).style.color = "red";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "lightblue";
            Cache.setItem('favorites', favs, { expires: expiration.getTime() })
            appsContext.dispatch({type:"FAVORITES", favorites:favs})           
            // console.log('added to favs.')
            // console.log('favs.', favs)
          }else{
            // e.target.style.color = 'white'
            document.getElementById("fav_"+appsContext.state.videoPlayer.assetObj.id).style.color = "white";
            // document.getElementById("fav_"+assetId).style.backgroundColor = "pink";
            const tmp = favs.filter(item => item !== appsContext.state.videoPlayer.assetObj.id)
            // console.log('tmp:', tmp)
            Cache.setItem('favorites', tmp, { expires: expiration.getTime() })
            favs = Cache.getItem('favorites')
            appsContext.dispatch({type:"FAVORITES", favorites:favs})
            // console.log('removed from favs.')
            // console.log('favs.:', favs)
         }
       }
       // console.log('appsContext.state.favorites:', appsContext.state.favorites)
        
      }else if(arg === 'metaSubmit'){
        // console.log('metaSubmit target:',e.target)
      }else if(arg === 'grabFrame'){
        console.log('grabFrame')
        // const frame = captureVideoFrame(this.player.getInternalPlayer())
      }
    }
  }  

  const handleCheckout = (e) =>{
    e.preventDefault()
    // console.log('handleCheckout...')
    navigate(
              "/ShopCart",
              // {
              //   state: { searchform },
              // }
            )
  }

  // const handleAuthStateChange = (state) => {
  //   // console.log('authState:', state)
  //     if (state === 'signedIn') {
  //         /* Do something when the user has signed-in */

  //     }else if(state === 'signIn'){

  //     }
  // }
// 
  // const handleDownload = (e) => {
  //   e.preventDefault()
  //         /* Do something when the user has signed-in */
  //         try{
  //           getVideoUrl('hrdl')
  //           vPlayer.isDownloading = true
  //           appsContext.dispatch({ type: "VPLAYER", vPlayer });
          
  //           // appsContext.dispatch({ type: "SRC", signedUrl: signedUrl.videoUrl });
  //         }catch(err){
            
  //           console.log('error: ', err)
  //         }
  // }


  // const handlePause = (e) => {
  //   if (e){ 
  //     e.preventDefault()
  //     console.log('onPause')
  //     // setState({ playing: false })
  //     vPlayer.playing = false
  //     appsContext.dispatch({ type: "VPLAYER",  vPlayer });
  //       // event.preventDefault();
  //       // console.log('props.location.pathname:', props.location.pathname)
  //         // console.log('clicked menu link:', event.target)
  //         // currentCategory = category
  //         // appsContext.dispatch({ type: "VPLAYER",  currentCategory});
  //     }
  // }

  const getUserAttributes = async () => {
    let user = await Auth.currentAuthenticatedUser();
    // console.log('user->', user)
    const { attributes } = user;

  }

  useEffect(() => {
      // Hub.listen('auth', listener);
      // appsContext.state.videoPlayer.hrDlSrc = ''
      //  console.log('appsContext.state.videoPlayer.hrDlSrc: ',appsContext.state.videoPlayer.hrDlSrc)

      // console.log('user data:', appsContext.state.user)
      
        // Hub.listen('auth', (data) => {
              
        //     /* console.log('data.payload.data: ',data.payload.data)
        //      console.log('data.payload.data.code: ',data.payload.data.code)
        //      */
        //     console.log('data.payload.data.name: ', data.payload.data.name)
        //     console.log('data.payload.data.message: ', data.payload.data.message)
        //     console.log('New auth event: ', data.payload.event);
        // })
        const favs = (Cache.getItem('shotwire-favorites')==null)?[]:Cache.getItem('shotwire-favorites')

        appsContext.dispatch({type:"FAVORITES", favorites:favs})

        const myDownloads = (Cache.getItem('shotwire-myDownloads')==null)?[]:Cache.getItem('shotwire-myDownloads')

        appsContext.dispatch({type:"MYDOWNLOADS", myDownloads:myDownloads})

        getUserAttributes()
              
              // getData();
              
              // const subscription = API.graphql(graphqlOperation(onCreateAlbum)).subscribe({
               //    next: eventData => {
               //      const album = eventData.value.data.onCreateAlbum;
               //      dispatch({ type: "SUBSCRIPTION", album });
               //    }
               //  });
                return () => {
                  // subscription.unsubscribe();
           
                };
          }, []);

  

  //settting auth state to 'loading' hide the default signin form
if (appsContext.state.user !== null) {
  const userInfo = appsContext.state.user
//get user roles is admin
  if(userInfo){
     return(
          <Layout>
          <Container>
          { 
          //   <Authenticator 
          //   errorMessage={ errMap }
          //   authState='loading'
          //   hideDefault={ true } 
          //   onStateChange={ handleAuthStateChange } 
          //   theme={ MyTheme }>                   
          //   <Greetings/>
          //   <AlwaysOn/>
          //   <SignIn/>
          //   <SignUp/>
          //   <ConfirmSignUp/>
          // </Authenticator>
        }
          <div sx={{
             
              padding: '10px',
              // backgroundColor: '#888'
              // borderBottom: '1px dashed #333'
          }}>
          <h3>Profile</h3>
          </div>
          </Container>
          <Container>
            <Row>
              <Col sm={12} lg={9}>              
                <div sx={{
                    padding: '10px',
                // backgroundColor: 'blue',
                width: '100%',
                height: 'auto'
                // objectFit: 'contain'
                      }}>
                    </div>

               

              </Col>
              <Col sm={12} lg={3}>
              
                  
                    <div sx={{
                        width: '100%',
                        padding: '10px',
                        // backgroundColor: '#444'
                      }}
                      >
                   
                      
                      <h6 className="text-uppercase mb-4 font-weight-bold">Your Stats</h6>
                      <div sx={{
                        width: '100%',
                        padding: '0px',
                        paddingBottom: '20px'
                      }}>
                      
                    

  
                      {' '}
                      
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          size="sm"
                          sx={{
                            marginRight: '0.5rem'
                          }}
                          onClick={e => handleClick(e, 'listCart')}
                        />{' '}<b>{ (appsContext.state.shopCart!==null)?appsContext.state.shopCart.length:0 }</b> in Cart<br />
                        {
                          (appsContext.state.shopCart!==null)? (appsContext.state.shopCart.length > 0) ?
                                                <Button 
                                                  variant="dark" href="#!" size="lg" 
                                                  onClick={e=>handleCheckout(e)}
                                                >View Cart</Button> :
                                                <span></span> : <span></span>
                                                
                        }
                      </div>
                      
                      <div sx={{
                        width: '100%',
                        padding: '0px',
                        paddingBottom: '20px'
                      }}>
                      
                    

  
                      {' '}
                      
                        <FontAwesomeIcon
                          icon={faDownload}
                          size="sm"
                          sx={{
                            marginRight: '0.5rem'
                          }}
                          onClick={e => handleClick(e, 'listDownloads')}
                        />{' '}<b>{ (appsContext.state.myDownloads!==null)?appsContext.state.myDownloads.length:0 }</b> Downloads 
                        
                      </div>
                    </div>
                  
                  <div sx={{
                        width: '100%',
                        padding: '10px',

                      }}>
                      <FontAwesomeIcon
                          icon={faStar}
                          size="sm"
                          sx={{
                            marginRight: '0.5rem'
                          }}
                          onClick={e => handleClick(e, 'listFavorites')}
                        />{' '}<b>{ (appsContext.state.favorites!==null)?appsContext.state.favorites.length:0 }</b> Favorites 
                    
                  
                  </div>

              </Col>
            </Row>
          </Container>

           
           </Layout>
           )
  }else{
    return(
          <Layout>
          <Container>

          { /*<h3>No Video Info</h3>*/ }
          <div sx={{
  padding: '10px',
 }} >
          <FontAwesomeIcon
            icon={faSpinner}
            size="2x"
            sx={{
              // marginRight: '0.5rem'
            }}
            spin
          />
          </div>
          </Container>
          </Layout>
          )
  }

}else{
        return(
          <Layout>
          <Container>

          { <h3>Not Authorized</h3> }
          
          </Container>
          </Layout>
          )
}

 
}
//if we make our own controls 
// { appsContext.state.videoPlayer.playing ? (
//                 <Button variant="dark" href="#!" size="md" onClick={handlePause}>
//                         <FontAwesomeIcon
//                           icon={faPause}
//                           size="lg"
//                           sx={{
//                             marginRight: '0.5rem'
//                           }}
//                         />
//                       </Button>
// ):(
//                       <Button variant="dark" href="#!" size="md" onClick={handlePlay}>
//                         <FontAwesomeIcon
//                           icon={faPlay}
//                           size="lg"
//                           sx={{
//                             marginRight: '0.5rem'
//                           }}
//                         />
//                       </Button>
//             )   
// }

// <p>
//           Query String:<br />
//           ?setId={ location.state.assetObj.realSetId }&setDate={ location.state.assetObj.setDate }&setName={ location.state.assetObj.name }&assetName={ location.state.assetName }
//           </p>
// <Layout>
//     <SEO title="Celebrity Candids" />
//     <h1>Hi from the second page</h1>
//     <p>Celebrity Candids</p>
//     <Link to="/">Go back to the homepage</Link>
//   </Layout>

export default ProfilePage